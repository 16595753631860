<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <div class="h-75px">
            <v-select
              v-model="formData.type_id"
              label="Type"
              item-text="text"
              item-value="index"
              :items="serverData.types"
              clearable
              outlined
              dense
              hide-details
              required
              :error-messages="type_idErrors"
              @input="$v.formData.type_id.$touch()"
              @blur="$v.formData.type_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px" v-if="isOrganization">
            <v-select
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              hide-details
            ></v-select>
          </div>
          <div class="h-75px" v-else>
            <v-autocomplete
              v-model="formData.client_id"
              label="Client"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-autocomplete
              v-model="formData.country_id"
              label="Country"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
              hide-details
              @change="onCountryChange"
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-autocomplete
              v-model="formData.state_id"
              label="State"
              item-text="title"
              item-value="id"
              :items="states"
              clearable
              outlined
              dense
              :error-messages="(isOrganization && !formData.organization_id) ? ['Select Organization']: []"
              :readonly="isOrganization && !formData.organization_id"
              @change="onStateChange"
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-autocomplete
              v-model="formData.city_id"
              label="City"
              item-text="title"
              item-value="id"
              :items="cities"
              clearable
              outlined
              dense
              @change="onCityChange"
              :error-messages="(isOrganization && !formData.organization_id) ? ['Select Organization']: []"
              :readonly="isOrganization && !formData.organization_id"
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-autocomplete
              v-model="formData.area_id"
              label="Area"
              item-text="title"
              item-value="id"
              :items="areas"
              clearable
              outlined
              dense
              :error-messages="(isOrganization && !formData.organization_id) ? ['Select Organization']: []"
              :readonly="isOrganization && !formData.organization_id"
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.address"
              label="Address"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.address_2"
              label="Address 2"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.postal_code"
              label="Postal code"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      type_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      type_id: 1,
      organization_id: null,
      client_id: null,
      country_id: null,
      state_id: null,
      city_id: null,
      area_id: null,
      address: null,
      address_2: null,
      postal_code: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/organization/addresses/create")
        .then((response) => {
          this.serverData = response.data;
          this.serverData.states = [];
          this.serverData.cities = [];
          this.serverData.areas = [];
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/organization/addresses/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Address has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    onCountryChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { country: val };
        if(this.isOrganization && this.formData.organization_id){
          data['organization_id'] = this.formData.organization_id
        }
        ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            this.serverData = { ...this.serverData };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    onStateChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { state: val };
        if(this.isOrganization && this.formData.organization_id){
          data['organization_id'] = this.formData.organization_id
        }
        ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            this.serverData = { ...this.serverData };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    onCityChange(val) {
      if (val) {
        this.pageLoader(true);
        let data = { city: val };
        if(this.isOrganization && this.formData.organization_id){
          data['organization_id'] = this.formData.organization_id
        }
        ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            this.serverData = { ...this.serverData };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        type_id: null,
        organization_id: null,
        client_id: null,
        country_id: null,
        state_id: null,
        city_id: null,
        area_id: null,
        address: null,
        address_2: null,
        postal_code: null,
      };
    },
  },
  computed: {
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    type_idErrors: function () {
      return this.handleFormValidation("type_id", this);
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
  },
};
</script>
